import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';

const Index = ({ data, location }) => (
  <Meta
    location={location}
    site={get(data, 'site.meta')}
    metaRefresh="0; url=https://yourvotematters.co.uk/?utm_source=saveourfuture" />
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query YourVoteMattersQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
